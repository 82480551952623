import { useContext, useEffect, useState } from 'react';
import { HonoredContext } from '../../context/HonoredProvider';
import { ColorRing } from 'react-loader-spinner';

import Header from '../Header/Header';
import MainImage from '../MainImage/MainImage';
import SearchBar from '../SearchBar/SearchBar';
import Honored from '../RecentHonored/RecentHonored';
import Footer from '../Footer/Footer';

import './MainPage.css';

const MainPage = () => {
  const { honored, getHonored, isLoading } = useContext(HonoredContext);
  const [honoredToShow, setHonoredToShow] = useState([]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  // console.log(honoredToShow);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getRandomItemsFromArray(arr, numItems) {
    if (numItems >= arr.length) {
      return arr; // Return the entire array if you want more items than available.
    }

    const shuffledArray = [...arr]; // Copy the original array to avoid modifying it.
    shuffleArray(shuffledArray);

    return shuffledArray.slice(0, numItems);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    const honoredAux = await getHonored();
    const selectedHonoredAux = getRandomItemsFromArray(honoredAux, 5);
    setHonoredToShow(selectedHonoredAux);
  }

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = (searchText) => {
    const filteredData = honored.filter((honor) => {
      return (
        honor.honorYear === searchText ||
        honor.name.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    return filteredData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('[MainPage] handle submit', e.target.searchText.value);
    const filteredData = filterData(e.target.searchText.value);
    setHonoredToShow(filteredData);
  };

  console.log(isLoading);

  return (
    <div className='main-page'>
      <div className='container'>
        <div className='banner'>
          <MainImage className='mt-10' />
        </div>
        <div className='header-container'>
          <Header />
        </div>
        <div className='main-text'>
          <p className='text-center'>
              


            A Câmara Municipal de Campinas outorga anualmente o diploma Zumbi
            dos Palmares às pessoas que mais se destacaram na defesa, na
            integração social dos membros da comunidade negra de Campinas, bem
            como na difusão da cultura afro-brasileira. Esse projeto tem como
            objetivo fazer uma pesquisa e um mapeamento das pessoas que foram
            homenageadas com o diploma, bem como publicizar as informações em um
            site de acesso público, onde possamos acessar esses dados e as
            biografias dessas pessoas.
          </p>
        </div>
        <div className='search-bar-container'>
          <SearchBar handleSubmit={handleSubmit} />
        </div>
        <div className='main-page-honored'>
          <div className='spinner'>
            {isLoading && (
              <ColorRing
                visible={true}
                height='80'
                width='80'
                ariaLabel='blocks-loading'
                wrapperStyle={{}}
                wrapperClass='blocks-wrapper'
                colors={['#fef200', '#ed1b24', '#146834', '#241c19', '#fef200']}
              />
            )}
          </div>
          {honoredToShow.length === 0 && (
            <p className='title2'>Não há homenageados para exibir</p>
          )}
          {honoredToShow.length > 0 && (
            <Honored honoredToShow={honoredToShow} />
          )}
        </div>
        <div className='footer-row-1'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
