import React, { useState} from 'react';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../constants/FirebaseConfig';
export const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState();
  // console.log(userCtx);

  // async function fetchUserId() {
  //   console.log('[AuthContext] fetch userId');
  //   const userName = await AsyncStorage.getItem('username');
  //   const userId = await AsyncStorage.getItem('userid');
  //   const userToken = await AsyncStorage.getItem('token');
  //   if (userId) {
  //     console.log('Setting isauthenticated to true');
  //     setAuthToken(userToken);
  //     await userCtx.fetchUser(userId);
  //     // console.log(
  //     //   '==================================================',
  //     //   userCtx
  //     // );
  //   }
  // }

  // useEffect(() => {
  //   fetchUserId();
  // }, []);

  // async function storeLocallyUserInfo(name, userId, token) {
  //   console.log('[Auth Context] storing name, userId, token', name, userId);
  //   try {
  //     await AsyncStorage.setItem('userid', userId);
  //     await AsyncStorage.setItem('displayname', name);
  //     await AsyncStorage.setItem('token', token);
  //   } catch (e) {
  //     console.log('Problem to save the key');
  //   }
  // }

  // async function signupUser(user) {
  //   console.log('[Auth Context] signup user starting...');
  //   const userCredential = await createUserWithEmailAndPassword(
  //     authentication,
  //     user.email,
  //     user.password
  //   );
  //   const auth = getAuth();
  //   const currentUser = auth.currentUser;
  //   await updateProfile(currentUser, {
  //     displayName: user.name,
  //     phoneNumber: user.phone,
  //   })
  //     .then(() => {
  //       console.log('Profile updated');
  //     })
  //     .catch((error) => {
  //       console.log('Error to update profile.');
  //     });
  //   const userData = userCredential.user;
  //   setAuthToken(userData.accessToken);
  //   storeLocallyUserInfo(user.name, userData.uid, userData.accessToken);
  //   user.id = userData.uid;
  //   user.role = GLOBALS.USER.ROLE.CONSUMER;
  //   user.createdAt = new Date().toString();
  //   delete user.password;
  //   addDocumentWithCustomId('users', user, user.id)
  //     .then((res) => {
  //       console.log('[AuthContext user created]', res);
  //     })
  //     .catch((err) => {
  //       console.log('[AuthContext problema to store user in Firebase]', err);
  //     });
  // }

  async function signin(email, password) {
    console.log('[AuthContext] starting signin', email, password);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    setAuthToken(userCredential._tokenResponse.idToken);
    // storeLocallyUserInfo(
    //   userCredential._tokenResponse.displayName,
    //   userCredential.user.uid,
    //   userCredential._tokenResponse.idToken
    // );
    console.log('[Authcontext] value', JSON.stringify(userCredential, null, 2));
  }

  function signout() {
    signOut(auth)
      .then(() => {
        console.log('[AuthContext] signout sucessfully');
        setAuthToken(null);
        // AsyncStorage.removeItem('token');
        // AsyncStorage.removeItem('userid');
      })
      .catch((error) => {
        console.log('[AuthContext] signout error', error);
      });
  }

  // async function resetPassword(email) {
  //   await sendPasswordResetEmail(authentication, email)
  //     .then(() => {
  //       console.log('[AuthContext] Password reset email sent!');
  //     })
  //     .catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       console.log(
  //         '[AuthContext] Error to send e-mail to reset password',
  //         error
  //       );
  //     });
  // }

  const value = {
    token: authToken,
    isAuthenticated: !!authToken,
    signin: signin,
    signout: signout,
    // resetPassword: resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
