import './Footer.css';
import FooterButton from './FooterButton';
import logoifspcampinas from '../../assets/images/logoifspcampinas-horizontal.png';
import logominhacampinas from '../../assets/images/logominhacampinas.png'
import { ifspCampinasUrl, minhaCampinasUrl } from '../../constants/Constants';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-row1'>
        <div className='footer-logo-container'>
          <p className='mb-10 white'>Realização</p>
          <a
            className='link'
            href={ifspCampinasUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={logoifspcampinas} alt='Logo do IFSP Campinas' />
          </a>
        </div>
        <div className='footer-logo-container'>
          <p className='mb-10 white'>Apoio</p>
          <a
            className='link'
            href={minhaCampinasUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={logominhacampinas} alt='Logo da organização Minha Campinas' />
          </a>
        </div>
        <div className='footer-item-container'>
          <FooterButton label='Diploma Zumbi dos Palmares' link='/sobre' />
        </div>
        <div className='footer-item-container'>
          <FooterButton label='Sobre o Projeto' link='/sobreoprojeto' />
        </div>
        <div className='footer-item-container'>
          <FooterButton label='Homenagens' link='/homenagens' />
        </div>
        <div className='footer-item-container'>
          <FooterButton label='Contato' link='/contato' />
        </div>
        <div className='footer-item-container'>
          <FooterButton label='Admin' link='/admin' />
        </div>
      </div>

      <div className='footer-row2'>
        <div className='license'>
          <a
            className='link mr-10'
            property='dct:title'
            rel='cc:attributionURL'
            href='https://diplomazumbidospalmares.org.br/'
          >
            Site by
          </a>
          <span property='cc:attributionName' className='author mr-10'><a className='link' href="mailto:albordignon@gmail.com">A.L.B.</a></span>
          is licensed under
          <a
            className='link ml-10'
            href='http://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1'
            target='_blank'
            rel='license noopener noreferrer'
            // style='display:inline-block;'
          >
            CC BY-NC-SA 4.0
            <img
              className='icon ml-10'
              src='https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1'
              alt='Ícone license'
            />
            <img
              className='icon ml-10'
              src='https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1'
              alt='Ícone license'
            />
            <img
              className='icon ml-10'
              src='https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1'
              alt='Ícone license'
            />
            <img
              className='icon ml-10'
              src='https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1'
              alt='Ícone license'
            />
          </a>
          {/* Instituto Federal de São Paulo - Campus Campinas */}
        </div>
        
      </div>
      <div className='footer-row3'>
        <p>Diploma Zumbi dos Palmares 1.2.2</p>
      </div>
    </div>
  );
};

export default Footer;
