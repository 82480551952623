import Footer from '../Footer/Footer';
import SideBar from '../SideBar/SideBar';
import './PageBase.css';

const PageBase = ({ children }) => {
  return (
    <div className='page-base'>
      <div className='page-base-row'>
        <div className='side-bar-container'>
          <SideBar />
        </div>
        <div className='page-base-main'>{children}</div>
      </div>
      <div className='page-base-footer'>
        <Footer />
      </div>
    </div>
  );
};
export default PageBase;
