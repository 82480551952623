import { NavLink } from 'react-router-dom';
import './HeaderButton.css';

const HeaderButton = ({ label, link }) => {
  return (
    <div className='header-button'>
      <NavLink to={link} className='color-change'>
        {label}
      </NavLink>
    </div>
  );
};

export default HeaderButton;
