import { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { ColorRing } from 'react-loader-spinner';
import { isValidEmail } from '../../util/validation';

function Authentication() {
  // console.log('[Authscreen] starting...');
  const [user, setUser] = useState({
    // email: { value: 'albordignon@gmail.com', isValid: true },
    // password: { value: 'ifsp@1234', isValid: true },
    email: { value: '', isValid: true },
    password: { value: '', isValid: true },
  });
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  // const [error, setError] = useState(null);
  // const [showPassword, setShowPassword] = useState(false);

  const authCtx = useContext(AuthContext);

  // console.log('[Authentication component] isAuthenticated', authCtx.isAuthenticated);

  function emailChangeHandler(e) {
    console.log('Email changed', e.target.value);
    const newUser = { ...user, email: { value: e.target.value, isValid: true } };
    setUser(newUser);
  }

  function passwordChangeHandler(e) {
    console.log('Password changed');
    const newUser = { ...user, password: { value: e.target.value, isValid: true } };
    setUser(newUser);
  }

  const signInHandler = async (event) => {
    event.preventDefault();
    console.log('[AuthScreen] Setting authenticating to true...');
    setIsAuthenticating(true);
    const userEmailIsValid = isValidEmail(user.email.value);
    if (!userEmailIsValid) {
      alert(
        'Entrada de dados inválida',
        'Por favor, verifique se os campos foram digitados corretamente.'
      );
      setUser({
        email: { value: user.email.value, isValid: userEmailIsValid },
        password: {
          value: user.password.value,
          isValid: user.password.isValid,
        },
      });
      console.log('[AuthScreen] userEmailIsValid line 44', userEmailIsValid);
      setIsAuthenticating(false);
      return;
    }
    try {
      await authCtx.signin(user.email.value, user.password.value);
      setIsAuthenticating(false);
    } catch (error) {
      alert(
        'Falha no login',
        'Verifique se o e-mail e a senha estão corretos.'
      );
    }
    setIsAuthenticating(false);
    // navigation.navigate('ManageDeliveryScreen');
  };

  // async function forgetPasswordHandler() {
  //   const userEmailIsValid = isValidEmail(user.email.value);
  //   if (user.email.value && userEmailIsValid) {
  //     await authCtx.resetPassword(user.email.value);
  //     Alert.alert(
  //       'E-mail enviado',
  //       'Um e-mail foi enviado para você resetar sua password.'
  //     );
  //   } else {
  //     Alert.alert(
  //       'Dados incorretos',
  //       'Por favor, verifique se o campo e-mail foi digitado corretamente.'
  //     );
  //   }
  // }

  // const formIsInvalid = !user.email.isValid || !user.password.isValid;

  return (
    <div className='authentication'>
      {isAuthenticating && (
        <div className='loading'>
          <ColorRing
            visible={true}
            height='80'
            width='80'
            ariaLabel='blocks-loading'
            wrapperStyle={{}}
            wrapperClass='blocks-wrapper'
            colors={['#fef200', '#ed1b24', '#146834', '#241c19', '#fef200']}
          />
        </div>
      )}
      <form className='search-bar-form' onSubmit={signInHandler}>
        <input
          className='input'
          type='text'
          id='e-mail'
          placeholder='Digite o e-mail'
          value={user.email.value}
          onChange={emailChangeHandler}

        />
        <input
          className='input'
          type='password'
          id='password'
          placeholder='Digite a senha'
          value={user.password.value}
          onChange={passwordChangeHandler}
        />
        <input className='button' type='submit' value='Logar' />
      </form>
    </div>
  );
}

export default Authentication;
