import { FaRegPaperPlane } from 'react-icons/fa';
import PageBase from '../PageBase/PageBase';
import './Contact.css';

const Contact = () => {
  return (
    <PageBase>
      <div className='contact'>
        <div className='contact-row-1'>
          <div className='contact-row-1-main'>
            <h1 className='title m-30'>Contato</h1>
            <p className='text'>
              Caso você queira enviar uma sugestão, fazer um comentário ou
              solicitar a inclusão de uma pessoa que já foi homenageada fique a
              vontade em nos contactar.{' '}
            </p>
            <div className='contact-email-container'>
              <FaRegPaperPlane className='email-icon' />
              <p className='mt-30 text'>diplomazumbidospalmares@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </PageBase>
  );
};
export default Contact;
