import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
} from 'firebase/firestore';
// import { uploadBytes } from 'firebase/storage';

import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../constants/FirebaseConfig';
export const HonoredContext = React.createContext();

const HonoredProvider = (props) => {
  // console.log('[Honored Provider started]');
  const [honored, setHonored] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function addHonored(honored) {
    console.log('[Honored Provider] add honored', honored);
    const honoredAux = [];
    setIsLoading(true);
    let photoUrl;
    if (honored.image.file) {
      photoUrl = await addPhotoToStorage(honored.image);
    }

    try {
      honored.photo = photoUrl;
      delete honored.image;
      const docRef = await addDoc(collection(db, 'honored'), {
        ...honored,
        createdAt: new Date(),
      });
      console.log(docRef);
      setIsLoading(false);
      return honoredAux;
    } catch (error) {
      console.log(
        '[Honored Provider] Ocorreu um erro ao adicionar os dados',
        error
      );
    }
  }

  async function addPhotoToStorage(photo) {
    const storageRef = ref(storage, `Photos/${photo.file.name}`);
    const uploadTask = await uploadBytesResumable(storageRef, photo.file);
    console.log(uploadTask);
    const photoUrl = await getDownloadURL(storageRef);
    // console.log(photoUrl);
    return photoUrl;
  }

  async function updateHonored(id, honored) {
    console.log('[Honored Provider] update honored', honored);
    const honoredAux = [];
    setIsLoading(true);
    // honored.photo = honored.photo.value;
    if (honored.image) {
      console.log('Vou atualizar a foto');
      honored.photo = await addPhotoToStorage(honored.image);
    }
    try {
      delete honored.image;
      console.log(honored);
      const docRef = doc(db, 'honored', id);
      await updateDoc(docRef, {
        ...honored,
        updatedAt: new Date(), 
      });

      console.log(docRef);
      setIsLoading(false);
      return honoredAux;
    } catch (error) {
      console.log(
        '[Honored Provider] Ocorreu um erro ao adicionar os dados',
        error
      );
    }
  }

  async function getHonored() {
    //Lê todas as pessoas homenageadas do banco de dados.
    // console.log('[Honored Provider] fetch data');
    const honoredAux = [];
    setIsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'honored'));
      querySnapshot.forEach((doc) => {
        const newHonored = {
          id: doc.id,
          name: doc.data().name,
          photo: doc.data().photo,
          biography: doc.data().biography,
          birthDate: doc.data().birthDate,
          city: doc.data().city,
          deathDate: doc.data().deathDate,
          decreeTitle: doc.data().decreeTitle,
          decreeURL: doc.data().decreeURL,
          facebook: doc.data().facebook,
          honorYear: doc.data().honorYear,
          instagram: doc.data().instagram,
          interviewURL: doc.data().interviewURL,
          linkedin: doc.data().linkedin,
          deleted: doc.data().deleted,
        };
        honoredAux.push(newHonored);
      });
      setHonored(honoredAux);
      setIsLoading(false);
      return honoredAux;
    } catch (error) {
      console.log(
        '[Honored Provider] Ocorreu um erro ao carregar os dados',
        error
      );
    }
    setIsLoading(false);
  }

  async function getHonoredById(id) {
    // console.log('[Honored Provider] fetch data by ID');
    setIsLoading(true);
    try {
      const docRef = doc(db, 'honored', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsLoading(false);
        return docSnap.data();
      } else {
        // docSnap.data() will be undefined in this case
        console.log('No such document!');
        setIsLoading(false);
      }
    } catch (error) {
      console.log(
        '[Honored Provider] Ocorreu um erro ao carregar os dados',
        error
      );
      setIsLoading(false);
    }
  }

  async function deleteHonored(id) {
    // Function to set the register to not be showed.
    // console.log('[Honored Provider] fetch data by ID');
    setIsLoading(true);
    try {
      const docRef = doc(db, 'honored', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, { deleted: true, deletedAt: new Date() });
        setIsLoading(false);
        return;
      } else {
        // docSnap.data() will be undefined in this case
        console.log('No such document!');
        setIsLoading(false);
      }
    } catch (error) {
      console.log(
        '[Honored Provider] Ocorreu um erro ao carregar os dados',
        error
      );
      setIsLoading(false);
    }
  }

  return (
    <HonoredContext.Provider
      value={{
        addHonored: addHonored,
        updateHonored: updateHonored,
        getHonored: getHonored,
        getHonoredById: getHonoredById,
        deleteHonored: deleteHonored,
        honored: honored,
        isLoading: isLoading,
      }}
    >
      {props.children}
    </HonoredContext.Provider>
  );
};

export default HonoredProvider;

// const storageRef = ref(storage, `Photos/${honored.photo.file.name}`);
//     const uploadTask = uploadBytesResumable(storageRef, honored.photo.file);
//     uploadTask.on(
//       'state_changed',
//       (snapshot) => {
//         const progress = Math.round(
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//         );
//         console.log(progress);
//         // setProgresspercent(progress);
//       },
//       (error) => {
//         alert(error);
//       },
//       async () => {
//         getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
//           console.log(downloadURL);
//           let photoUrl = downloadURL;
//           try {
//             honored.photo = photoUrl;
//             delete honored.image;
//             const docRef = await addDoc(collection(db, 'honored'), {
//               ...honored,
//               createdAt: new Date(),
//             });
//             console.log(docRef);
//             setIsLoading(false);
//             return honoredAux;
//           } catch (error) {
//             console.log(
//               '[Honored Provider] Ocorreu um erro ao adicionar os dados',
//               error
//             );
//           }
//         });
//       }
//     );
