import { useContext, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ColorRing } from 'react-loader-spinner';
import { ImagesContext } from '../../context/ImagesProvider';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import PageBase from '../PageBase/PageBase';
import './ImageGallery.css';

const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const { fetchImages } = useContext(ImagesContext);

  const getImages = async () => {
    const imagesAux = await fetchImages();
    setImages(imagesAux);
    console.log(imagesAux);
  };

  useEffect(() => {
    setOnLoading(true);
    getImages();
    setOnLoading(false);
  }, []);

  return (
    <PageBase>
      <div className='image-gallery'>
        <div className='image-gallery-row-1'>
          <div className='image-gallery-main'>
            <h1 className='title m-30'>Galeria de imagens</h1>
            <div className='images-container'>
              {onLoading && (
                <div className='loading'>
                  <h3>Carregando</h3>
                  <ColorRing
                    visible={true}
                    height='80'
                    width='80'
                    ariaLabel='blocks-loading'
                    wrapperStyle={{}}
                    wrapperClass='blocks-wrapper'
                    colors={[
                      '#fef200',
                      '#ed1b24',
                      '#146834',
                      '#241c19',
                      '#fef200',
                    ]}
                  />
                </div>
              )}
                <Carousel className='carousel' autoPlay='true' interval={3000}>
                  {images.map((image, index) => {
                    return (
                      <div key={index}>
                        <img
                          className='photo'
                          src={image.url}
                          alt={image.legend}
                        />
                        <p className='legend'>{image.legend}</p>
                      </div>
                    );
                  })}
                </Carousel>
            </div>
          </div>
        </div>
      </div>
    </PageBase>
  );
};

export default ImageGallery;
