import { Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage/MainPage';
// import RegisterPage from './components/RegisterPage/RegisterPage';
import About from './components/About/About';
import Honored from './components/Honored/Honored';
import ImageGallery from './components/ImageGallery/ImageGallery';
import AboutTheProject from './components/AboutTheProject/AboutTheProject';
import Contact from './components/Contact/Contact';
import Links from './components/Links/Links';
import HonoredPage from './components/HonoredPage/HonoredPage';
// import AddPhotos from './components/AddPhotos/AddPhotos';
import AdminPage from './components/AdminPage/AdminPage';
// import Soon from './components/Soon/Soon';

const routes = () => {  
  return (
    <Routes>
      <Route path='/' element={<MainPage />} />
      {/* <Route path='/' element={<Soon />} />  */}
      <Route path='/sobre' element={<About />} />
      <Route path='/homenagens' element={<Honored />} />
      <Route path='/galeriadeimagens' element={<ImageGallery />} />
      <Route path='/sobreoprojeto' element={<AboutTheProject />} />
      <Route path='/links' element={<Links />} />
      <Route path='/contato' element={<Contact />} />
      <Route path='/pessoahomenageda/:id' element={<HonoredPage />} />
      {/* <Route path='/teste' element={<Contact />} /> */}
      {/* <Route path='/cadastro' element={<RegisterPage />} /> */}
      {/* <Route path='/fotos' element={<AddPhotos />} /> */}
      <Route path='/admin' element={<AdminPage />} />

      {/* <Route path='/privacypolicy' element={<PrivacyPolicy />} /> */}
    </Routes>
  );
};

export default routes;
