import './SearchBar.css';

const SearchBar = ({ handleSubmit }) => {
  // const combinedClasses = classNames('Title', className);
  return (
    <form className='search-bar-form' onSubmit={handleSubmit}>
      <input className='search-bar-input' type='text' id='searchText' placeholder='Digite o nome ou o ano da pessoa homenageada'/>
      <input className='search-bar-button' type='submit' value='Pesquisar' />
    </form>
  );
};

export default SearchBar;
