import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { db } from '../constants/FirebaseConfig';
export const ImagesContext = React.createContext();

const ImagesProvider = (props) => {
  const [images, setImages] = useState([]);

  async function fetchImages() {
    const imagesAux = [];
    const querySnapshot = await getDocs(collection(db, 'images'));
    querySnapshot.forEach((doc) => {
      const newImage = {
        id: doc.id,
        name: doc.data().name,
        legend: doc.data().legend,
        url: doc.data().url,
      };
      imagesAux.push(newImage);
    });
    setImages(imagesAux);
    return imagesAux;
  }

  useEffect(() => {
    fetchImages();
  }, []);

  const deleteImage = async (image) => {
    console.log('[Images Provider] image', image);
    try {
      await deleteDoc(doc(db, 'images', image.id));
      //delete the file of storage
      const storage = getStorage();
      // Create a reference to the file to delete
      const imageRef = ref(storage, 'Images/'+image.name);

      // Delete the file
      deleteObject(imageRef)
        .then(() => {
          console.log('Arquivo de imagem apagado!')
        })
        .catch((error) => {
          console.log('Ocorreu um erro ao apagar a imagem!', error)
        });

      fetchImages();
    } catch (error) {
      alert('Problema para remover o registro da imagem.');
      console.log('[RegisterPage] erro ao remover os dados', error.message);
    }
  };

  // const navigate = useNavigate();

  return (
    <ImagesContext.Provider
      value={{
        images: images,
        fetchImages: fetchImages,
        deleteImage: deleteImage,
      }}
    >
      {props.children}
    </ImagesContext.Provider>
  );
};

export default ImagesProvider;
