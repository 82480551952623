import { useState, useContext } from 'react';
import PageBase from '../PageBase/PageBase';
import RegisterPage from '../RegisterPage/RegisterPage';
import AddPhotos from '../AddPhotos/AddPhotos';
import Authentication from '../Authentication/Authentication';
import { AuthContext } from '../../context/AuthProvider';
import './AdminPage.css';

const AdminPage = () => {
  const [addHonoredClicked, setAddHonoredClicked] = useState(true);
  const [addImageClicked, setAddImageClicked] = useState(false);

  const authCtx = useContext(AuthContext);

  return (
    <PageBase>
      <div className='admin-page'>
        {!authCtx.isAuthenticated ? (
          <div className='authentication-container'>
            <p className='text text-center'>
              Para acessar os recursos dessa página você precisa estar logada.
            </p>
            <Authentication />
          </div>
        ) : (
          <div>
            <div className='buttons-container mt-30'>
              <button
                className='button'
                onClick={() => {
                  setAddHonoredClicked(true);
                  setAddImageClicked(false);
                }}
              >
                Incluir pessoa homenageada
              </button>
              <button
                className='button'
                onClick={() => {
                  setAddHonoredClicked(false);
                  setAddImageClicked(true);
                }}
              >
                Incluir foto
              </button>
            </div>
            {addHonoredClicked && (
              <div>
                <RegisterPage />
              </div>
            )}
            {addImageClicked && (
              <div>
                <AddPhotos />
              </div>
            )}
          </div>
        )}
      </div>
    </PageBase>
  );
};

export default AdminPage;
