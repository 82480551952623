import PageBase from '../PageBase/PageBase';
import './About.css';
import {lawUrl} from '../../constants/Constants';

const About = () => {

  return (
    <PageBase>
      <div className='about'>
        <h1 className='title mb-30'>Sobre o diploma</h1>
        <p className='text'>
          A violência contra a população negra no Brasil vem aumentando e o
          racismo estrutural permeia em todas as relações de poder e diariamente
          mostra a sua cara. Casos como o de George Floyd no Estados Unidos, tem
          se repetido em nosso país de forma sistemática. Para lutar contra o
          racismo, a educação é uma ferramenta fundamental. Com ela podemos
          mostrar a importância que os negros e negras tiveram e tem para a
          construção de nosso país, e ao mesmo tempo, colaborar para o
          cumprimento da lei 10.639/2003 e coibir o apagamento histórico de
          personalidades negras da Cidade de Campinas. Para contribuir nesse
          processo, a Câmara Municipal de Campinas outorga anualmente o diploma
          Zumbi dos Palmares às pessoas que mais se destacaram na defesa, na
          integração social dos membros da comunidade negra de Campinas, bem
          como na difusão da cultura afro-brasileira. Esse projeto tem como
          objetivo fazer uma pesquisa e um mapeamento das pessoas que foram
          homenageadas com o diploma, bem como publicizar as informações em um
          site de acesso público, onde possamos acessar esses dados e as
          biografias dessas pessoas.
        </p>
        <a className='link mt-30' href={lawUrl} target="_blank" rel="noopener noreferrer">
          Link para a lei
        </a>
      </div>
    </PageBase>
  );
};
export default About;
