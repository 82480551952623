import { useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './RegisterPage.css';
import ShowHonored from './ShowHonored';
import PuffLoader from 'react-spinners/PuffLoader';
import {
  isValueNotBlank,
  isValidUrl,
  isNumber,
  isValidDateFormat,
} from '../../util/validation';
import photoplacehold from '../../assets/images/photoplacehold.png';
import SetHonoredObject from './SetHonoredObject';
import Card from '../Card/Card';
import { HonoredContext } from '../../context/HonoredProvider';

const RegisterPage = () => {
  const honoredAux = SetHonoredObject();
  const [form, setForm] = useState(honoredAux);
  const [selectedFile, setSelectedFile] = useState(null);
  const [honoredList, setHonoredList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [idToEdit, setIdToEdit] = useState(null);

  const { addHonored, updateHonored, getHonored, deleteHonored } =
    useContext(HonoredContext);

  // console.log('[RegisterPage] loading', isLoading);

  // console.log('Form Inputs - BirthDate', JSON.stringify(form.birthDate.value, null, 2));
  // console.log('Form Inputs - BirthDate', JSON.stringify(honoredList, null, 2));

  // console.log('Form Inputs', JSON.stringify(SetHonoredObject(), null, 2));

  const fetchHonored = async () => {
    console.log('Fetch honored');
    try {
      const honoredListAux = await getHonored();

      honoredListAux.sort((a, b) => {
        const nameA = a.name.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        const nameB = b.name.toLowerCase();
      
        if (nameA < nameB) {
          return -1; // Negative value means "a" comes before "b"
        }
      
        if (nameA > nameB) {
          return 1; // Positive value means "a" comes after "b"
        }
      
        return 0; // 0 means names are equal, no change in order
      });

      setHonoredList(honoredListAux);
    } catch (error) {
      console.log('[RegisterPager] error to get honored', error.message);
    }
  };

  useEffect(() => {
    // console.log('Started useEffect');
    setIsLoading(true);
    const honoredAux = SetHonoredObject();
    setForm(honoredAux);
    fetchHonored();
    setIdToEdit(null);
    setIsLoading(false);
  }, []);

  const validateForm = () => {
    const nameIsValid = isValueNotBlank(form.name.value);
    const photoIsValid = isValueNotBlank(form.photo.value);
    const birthDateIsValid = isValidDateFormat(form.birthDate.value);
    const deathDateIsValid = true;
    const biografyIsValid = isValueNotBlank(form.biography.value);
    const interviewURLIsValid = true;
    const honorYearIsValid = isNumber(parseInt(form.honorYear.value));
    const cityIsValid = true;
    const decreeTitleIsValid = isValueNotBlank(form.decreeTitle.value);
    // const decreeURLIsValid = isValidUrl(form.decreeURL.value);
    const decreeURLIsValid = isValueNotBlank(form.decreeURL.value);
    const isFacebookValid = true;
    const isInstagramValid = true;
    const isLinkedinValid = true;

    // console.log(nameIsValid);
    // console.log(photoIsValid);
    // console.log(birthDateIsValid);
    // console.log(deathDateIsValid);
    // console.log(biografyIsValid);
    // console.log(honorYearIsValid);
    // console.log(cityIsValid);
    // console.log(decreeTitleIsValid);
    // console.log(decreeURLIsValid);
    // console.log(isFacebookValid);
    // console.log(isInstagramValid);
    // console.log(isLinkedinValid);

    if (
      !nameIsValid ||
      !photoIsValid ||
      !birthDateIsValid ||
      !deathDateIsValid ||
      !biografyIsValid ||
      !interviewURLIsValid ||
      !honorYearIsValid ||
      !cityIsValid ||
      !decreeTitleIsValid ||
      !decreeURLIsValid ||
      !isFacebookValid ||
      !isInstagramValid ||
      !isLinkedinValid
    ) {
      const formAux = { ...form };
      console.log('Validate',formAux);
      formAux.name.value = form.name.value;
      formAux.name.isValid = nameIsValid;
      formAux.photo.value = form.photo.value;
      formAux.photo.isValid = photoIsValid;
      formAux.birthDate.value = form.birthDate.value;
      formAux.birthDate.isValid = birthDateIsValid;
      formAux.deathDate.value = form.deathDate.value;
      formAux.deathDate.isValid = deathDateIsValid;
      formAux.biography.value = form.biography.value;
      formAux.biography.isValid = biografyIsValid;
      formAux.interviewURL.value = form.interviewURL.value;
      formAux.interviewURL.isValid = interviewURLIsValid;
      formAux.honorYear.value = form.honorYear.value;
      formAux.honorYear.isValid = honorYearIsValid;
      formAux.city.value = form.city.value;
      formAux.city.isValid = cityIsValid;
      formAux.decreeTitle.value = form.decreeTitle.value;
      formAux.decreeTitle.isValid = decreeTitleIsValid;
      formAux.decreeURL.value = form.decreeURL.value;
      formAux.decreeURL.isValid = decreeURLIsValid;
      formAux.facebook.value = form.facebook.value;
      formAux.facebook.isValid = isFacebookValid;
      formAux.instagram.value = form.instagram.value;
      formAux.instagram.isValid = isInstagramValid;
      formAux.linkedin.value = form.linkedin.value;
      formAux.linkedin.isValid = isLinkedinValid;
      console.log('Validate pós',formAux);

      setForm(formAux);
      return false;
    } else {
      return true;
    }
  };

  const handleChangeName = (e) => {
    const newFormAux = { ...form };
    newFormAux.name.value = e.target.value;
    setForm(newFormAux);
  };

  const handleFileChange = async (event) => {
    // console.log('Photo ==> ', event.target.files[0]);
    const newFormAux = { ...form };
    const file = event.target.files[0];
    if(file.size > 1024 * 1000) {
      console.log(file.size);
      alert('Tamanho da foto não pode ser maior que 1MB');
      return;
    }
    if (file) {
      const reader = new FileReader();
      const readAsDataURL = () =>
        new Promise((resolve) => {
          reader.onload = (e) => {
            resolve({ image: file.name, name: e.target.result, file: file });
          };
        });
      reader.readAsDataURL(file);
      // reader.readAsDataURL(compressedResult);

      newFormAux.image = await readAsDataURL();
      // console.log('Depois da conversão', newFormAux.photo);
      newFormAux.photo.isValid = true;
      newFormAux.photo.value = newFormAux.image.name;
      setForm(newFormAux);
      // console.log('[Register page] form',newFormAux);
    } else {
      console.log('Problema ao carregar o arquivo');
    }
    // console.log(newFormAux);
    // setSelectedFile(event.target.files[0]);
  };

  const handleChangeBiografy = (e) => {
    const newFormAux = { ...form };
    newFormAux.biography.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeInterviewURL = (e) => {
    const newFormAux = { ...form };
    newFormAux.interviewURL.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeBirthDate = (date) => {
    const newFormAux = { ...form };
    newFormAux.birthDate.value = date;
    // newFormAux.birthDate.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeDeathDate = (date) => {
    const newFormAux = { ...form };
    console.log(date);
    newFormAux.deathDate.value = date;
    setForm(newFormAux);
  };

  const handleChangeHonorYear = (e) => {
    const newFormAux = { ...form };
    newFormAux.honorYear.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeCity = (e) => {
    const newFormAux = { ...form };
    newFormAux.city.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeDecreeTitle = (e) => {
    const newFormAux = { ...form };
    newFormAux.decreeTitle.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeDecreeURL = (e) => {
    const newFormAux = { ...form };
    newFormAux.decreeURL.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeFacebook = (e) => {
    const newFormAux = { ...form };
    newFormAux.facebook.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeInstagram = (e) => {
    const newFormAux = { ...form };
    newFormAux.instagram.value = e.target.value;
    setForm(newFormAux);
  };

  const handleChangeLinkedin = (e) => {
    const newFormAux = { ...form };
    newFormAux.linkedin.value = e.target.value;
    setForm(newFormAux);
  };

  const changeRecord = (honoredId) => {
    console.log('[Register Page] Change record id =]', honoredId);
    setIdToEdit(honoredId);
    const honoredAux = honoredList.find((item) => item.id === honoredId);
    console.log(honoredAux);
    const honored = SetHonoredObject(
      honoredAux.name,
      honoredAux.photo,
      honoredAux.birthDate,
      honoredAux.deathDate,
      honoredAux.biography,
      honoredAux.interviewURL,
      honoredAux.honorYear,
      honoredAux.city,
      honoredAux.decreeTitle,
      honoredAux.decreeURL,
      honoredAux.facebook,
      honoredAux.instagram,
      honoredAux.linkedin
    );
    console.log(honored);

    setForm(honored);
  };

  const deleteRecord = async (honoredId) => {
    console.log('[Register page] delete honored');
    const honored = honoredList.find((item) => item.id === honoredId);
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      'Confirma a deleção da pessoa homenageada ' + honored.name
    );
    if (confirmation) {
      try {
        await deleteHonored(honoredId);
        alert('Homenageado deletado com sucesso !!!');
        fetchHonored();
      } catch (error) {
        alert('Problema para remover o registro da pessoa homenageada.');
        console.log('[RegisterPage] erro ao remover os dados', error.message);
      }
    }
  };

  // console.log('Form exibido na linha 286', form);

  const handleAddOrChangeRecord = async (e) => {
    e.preventDefault();
    console.log('Form exibido na linha', form);
    const formIsValid = validateForm();
    if (formIsValid) {
      // console.log('[Register page] handleAddOrChangeRecord', form.photo);
      const honored = {
        name: form.name.value,
        image: form.image,
        photo: form.photo.value,
        birthDate: form.birthDate.value,
        deathDate: form.deathDate.value,
        biography: form.biography.value,
        honorYear: form.honorYear.value,
        city: form.city.value,
        decreeTitle: form.decreeTitle.value,
        decreeURL: form.decreeURL.value,
        interviewURL: form.interviewURL.value,
        facebook: form.facebook.value,
        instagram: form.instagram.value,
        linkedin: form.linkedin.value,
      };
      // console.log('[Register page ] Honored',honored.photo)
      try {
        if (idToEdit) {
          // console.log('[RegisterPage] edit before send to honored provider', idToEdit, honored);
          updateHonored(idToEdit, honored);
          setIdToEdit(null);
        } else {
          console.log('[RegisterPage] include');
          addHonored(honored);
          // const docRef = await addDoc(collection(db, 'honored'), {
          //   ...honored,
          //   createdAt: new Date(),
          // });
          // console.log(docRef);
        }
        alert('Dados gravados ou alterados com sucesso');
        setForm(SetHonoredObject());
      } catch (error) {
        alert('Dados válidos, mas não foi possível gravar os dados.');
        console.log('[RegisterPage] erro ao gravar os dados', error.message);
      }
      fetchHonored();
    } else {
      alert('Dados inválidos.');
      console.log('Erros');
      Object.entries(form).map(([key, value]) => {
        if (!value.isValid) {
          console.log(value.errorMessage);
        }
        // console.log(`${key}: ${value}`);
      });
    }
  };

  if (isLoading) {
    return <PuffLoader color='#36d7b7' />;
  }

  return (
    <div className='RegisterPage'>
      <p className='title mt-30 text-center'>
        Formulário de cadastro de novo homenageado
      </p>
      <p className='text'>
        Número de homenageados cadastrados: {honoredList.length}
      </p>
      <form className='Form' onSubmit={handleAddOrChangeRecord}>
        {/* <img src={form.photo.value.name} alt='dd' /> */}
        <div className='ErrorMessage'>
          {Object.entries(form).map(([key, value]) => {
            if (!value.isValid) {
              return <p key={key}>{value.errorMessage}</p>;
            }
          })}
        </div>
        <label>Nome</label>
        <input
          className='Input'
          type='text'
          placeholder='Nome completo'
          name='name'
          value={form.name.value}
          onChange={handleChangeName}
        />
        <div className='FileInputContainer'>
          <div className='FileInputButtonContainer'>
            <label className='custom-file-button'>Foto</label>
            <br />
            <small className='mb-2 white'>
              A foto deve ser quadrada. ex. 300x300px e seu tamanho não pode
              ultrapassar 1MB.
            </small>
            <input
              type='file'
              id='fileInput'
              accept='image/*'
              onChange={handleFileChange}
            />
          </div>
          <div className='PhotoContainer'>
            {/* <Photo
              photo={form.photo.value ? form.photo.value : photoplacehold}
            /> */}
            <Card
              honored={{
                name: form.name.value,
                photo: form.photo.value ? form.photo.value : photoplacehold,
                honorYear: form.honorYear.value,
              }}
            />
          </div>
        </div>
        <label>Nascimento</label>
        <br />
        <DatePicker
          className='Input'
          selected={form.birthDate.value}
          onChange={handleChangeBirthDate}
          dateFormat='dd-MM-yyyy'
        />
        <br />
        <label>Morte</label>
        <br />
        <DatePicker
          className='Input'
          selected={form.deathDate.value}
          onChange={handleChangeDeathDate}
          dateFormat='dd-MM-yyyy'
        />
        <br />
        <label>Biografia</label>
        <textarea
          rows='6'
          className='Input Biografy'
          type='text'
          placeholder='Biografia'
          name='biografy'
          value={form.biography.value}
          onChange={handleChangeBiografy}
        />
        <label>Entrevista</label>
        <input
          className='Input'
          type='url'
          placeholder='URL da entrevista'
          name='interview'
          value={form.interviewURL.value}
          onChange={handleChangeInterviewURL}
        />
        <label>Ano da homenagem</label>
        <input
          className='Input'
          name='honorYear'
          id='honorYear'
          type='number'
          min='1998'
          max='2023'
          step='1'
          value={form.honorYear.value}
          onChange={handleChangeHonorYear}
        />
        <label>Cidade</label>
        <input
          className='Input'
          type='text'
          placeholder='Cidade'
          name='city'
          value={form.city.value}
          onChange={handleChangeCity}
        />
        <label>Título do Decreto</label>
        <input
          className='Input'
          type='text'
          placeholder='Título do Decreto'
          name='decreeTitle'
          value={form.decreeTitle.value}
          onChange={handleChangeDecreeTitle}
        />
        <label>Link do Decreto</label>
        <input
          className='Input'
          type='url'
          placeholder='Link do Decreto'
          name='decreeURL'
          value={form.decreeURL.value}
          onChange={handleChangeDecreeURL}
        />
        <label>Facebook</label>
        <input
          className='Input'
          type='url'
          placeholder='Facebook'
          name='facebook'
          value={form.facebook.value}
          onChange={handleChangeFacebook}
        />
        <label>Instagram</label>
        <input
          className='Input'
          type='url'
          placeholder='Instagram'
          name='instagram'
          value={form.instagram.value}
          onChange={handleChangeInstagram}
        />
        <label>Linkedin</label>
        <input
          className='Input'
          type='url'
          placeholder='Linkedin'
          name='linkedin'
          value={form.linkedin.value}
          onChange={handleChangeLinkedin}
        />
        <input type='submit' className='button' value='Gravar' />
      </form>
      {isLoading ? (
        <PuffLoader color='#36d7b7' />
      ) : (
        <div className='HonoredList'>
          <ShowHonored
            honoredList={honoredList}
            changeRecord={changeRecord}
            deleteRecord={deleteRecord}
          />
        </div>
      )}
    </div>
  );
};

export default RegisterPage;
