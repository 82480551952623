import './MainImage.css';
import BannerImage from '../../assets/images/banner.png';
import { NavLink } from 'react-router-dom';

const MainImage = () => {
  return (
    <div className='main-image-container'>
      <NavLink to='/'>
        <img
          className='BannerImage'
          src={BannerImage}
          alt='Imagem principal do projeto. '
        />
      </NavLink>
    </div>
  );
};

export default MainImage;
