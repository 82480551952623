import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Routes from './Routes';
import { Helmet } from 'react-helmet';
import HonoredProvider from './context/HonoredProvider';
import ImagesProvider from './context/ImagesProvider';
import AuthProvider from './context/AuthProvider';
import './commonstyles/colors.css';
import './commonstyles/textalignment.css';
import './commonstyles/spacing.css';
import './App.css';

function App() {
  ReactGA.initialize('G-MY0MFSWYRG');

  return (
    <div className='App'>
      <Helmet>
        <title>Diploma Zumbi dos Palmares</title>
        <meta name="Diploma Zumbi do Palmares" content="Diploma Zumbi dos Palmares" />
        <meta property="og:title" content="Diploma Zumbi dos Palmares" />
        <meta property="og:description" content="Que tal conhecer um pouco melhor as pessoas que já foram homenageadas pela câmara municipal de Campinas com o Diploma Zumbi dos Palmares" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/diplomazumbi.appspot.com/o/banner.png?alt=media&token=b24b99c2-2458-4b0f-8926-2ac56838aea1" />
        <meta property="og:url" content="https://diplomazumbidospalmares.org.br/" />
        {/* Add more meta tags as needed */}
      </Helmet>
      <BrowserRouter>
        <AuthProvider>
          <ImagesProvider>
            <HonoredProvider>
              <Routes />
            </HonoredProvider>
          </ImagesProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
