import { NavLink } from 'react-router-dom';
import './FooterButton.css';

const FooterButton = ({ label, link }) => {
  return (
      <NavLink to={link} className='footer-button'>
        {label}
      </NavLink>
  );
};

export default FooterButton;
