import { NavLink } from 'react-router-dom';
// import classNames from 'classnames';
import './Card.css';

const Card = ({ className, honored }) => {
  // const combinedClasses = classNames('Title', className);

// console.log('Card', honored);

  return (
    <div className='card'>
      <NavLink to={`/pessoahomenageda/${honored.id}`} className='footer-button'>
        <div className='card-photo-container'>
          <img src={honored.photo} alt={honored.alt} />
        </div>
        <div className='card-text-container'>
          <div className='card-name'>
            <p>{honored.name}</p>
          </div>
          <div className='card-year'>
            <p>{honored.honorYear}</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default Card;
