const SetHonoredObject = (
  name,
  photo,
  birthDate,
  deathDate,
  biography,
  interviewURL,
  honorYear,
  city,
  decreeTitle,
  decreeURL,
  facebook,
  instagram,
  linkedin
) => {
  const honored = {
    name: {
      // value: name || '',
      value: name || 'Machado de Assis',
      isValid: true,
      errorMessage: 'Nome é obrigatório.',
    },
    photo: {
      value: photo || '',
      isValid: true,
      errorMessage: 'Foto é obrigatória',
    },
    birthDate: {
      // value: birthDate ? birthDate.toDate() : '',
      value: birthDate ? birthDate.toDate() : new Date(),
      isValid: true,
      errorMessage: 'Data de nascimento é obrigatória.',
    }, 
    deathDate: {
      value: deathDate ? deathDate.toDate() : '',
      isValid: true,
      errorMessage: '',
    },
    biography: {
      // value: biography || '',
      value: biography || 'Biografia',
      isValid: true,
      errorMessage: 'Biografia é obrigatória.',
    },
    honorYear: {
      value: honorYear || '1998',
      // value: honorYear || '',
      isValid: true,
      errorMessage: 'Ano de homenagem é obrigatório.',
    },
    decreeTitle: {
      // value: decreeTitle || '',
      value: decreeTitle || 'titulo do decreto',
      isValid: true,
      errorMessage: 'Título do decreto é obrigatório.',
    },
    decreeURL: {
      // value: decreeURL || '', 
      value: decreeURL || 'https://diplomazumbi.web.app/cadastro',

      isValid: true,
      errorMessage: 'Link do decreto é obrigatória.',
    },
    city: { value: city || '', isValid: true, errorMessage: '' },
    interviewURL: {
      value: interviewURL || '',
      isValid: true,
      errorMessage: '',
    },
    facebook: { value: facebook || '', isValid: true, errorMessage: '' },
    instagram: { value: instagram || '', isValid: true, errorMessage: '' },
    linkedin: { value: linkedin || '', isValid: true, errorMessage: '' },
  };

  return honored;
};

export default SetHonoredObject;

// const honored = {
//   name: {
//     value: name || 'André',
//     isValid: true,
//     errorMessage: 'Nome é obrigatório.',
//   },
//   photo: { value: photo || '', isValid: true, errorMessage: 'Foto é obrigatória' },
//   birthDate: {
//     value: birthDate || new Date(),
//     isValid: true,
//     errorMessage: 'Data de nascimento é obrigatória.',
//   },
//   deathDate: {
//     value: deathDate || new Date(),
//     isValid: true,
//     errorMessage: '',
//   },
//   biography: {
//     value: biography || 'Teste de biografia',
//     isValid: true,
//     errorMessage: 'Biografia é obrigatória.',
//   },
//   interviewURL: {
//     value: interviewURL || 'Teste de biografia',
//     isValid: true,
//     errorMessage: '',
//   },
//   honorYear: {
//     value: honorYear || '2023',
//     isValid: true,
//     errorMessage: 'Ano de homenagem é obrigatório.',
//   },
//   city: { value: city || 'Campinas', isValid: true, errorMessage: '' },
//   decreeTitle: { value: decreeTitle || 'sdfldkjf', isValid: true, errorMessage: '' },
//   decreeURL: { value: decreeURL || 'http://www.com.br', isValid: true, errorMessage: '' },
//   interviewURL: {
//     value: interviewURL || 'http://www.com.br',
//     isValid: true,
//     errorMessage: '',
//   },
//   facebook: { value: facebook || 'facebook', isValid: true, errorMessage: '' },
//   instagram: { value: instagram || 'instagram', isValid: true, errorMessage: '' },
//   linkedin: { value: linkedin || 'linkedin', isValid: true, errorMessage: '' },
// };
