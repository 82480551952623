import PageBase from '../PageBase/PageBase';
import { aboutProjectText, methodology, team } from '../../constants/Constants';
import './AboutTheProject.css';

const AboutTheProject = () => {
  return (
    <PageBase>
      <div className='about-the-project'>
        <h1 className='title m-30'>Sobre o projeto</h1>
        <p className='text '>
          Esse projeto foi desenvolvido pelo Instituto Federal de São Paulo -
          campus Campinas.
        </p>
        <div className='text'>
          {aboutProjectText.map((line) => {
            console.log(line.paragraph);
            return <p className='mt-10 text-justify'>{line.paragraph}</p>;
          })}
        </div>
        <h1 className='title m-30'>Metodologia</h1>
        <div className='text'>
          {methodology.map((line) => {
            console.log(line.paragraph);
            return <p className='mt-10 text-justify'>{line.paragraph}</p>;
          })}
        </div>        <h1 className='title m-30'>Equipe</h1>
        <div className='team-container'>
          {team
            .sort((a, b) => {
              return a.name.localeCompare(b.name);
            })
            .map((member) => {
              return (
                <div className='card'>
                  <div className='card-photo-container'>
                    <img src={member.photo} alt={member.alt} />
                  </div>
                  <div className='card-text-container'>
                    <div className='card-name text-center'>
                      <p>{member.name}</p>
                    </div>
                    <div className='card-position text-center mt-10'>
                      <p>{member.position}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </PageBase>
  );
};

export default AboutTheProject;
