import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import { ColorRing } from 'react-loader-spinner';
import CardWithDate from '../CardWithDate/CardWithDate';
import { HonoredContext } from '../../context/HonoredProvider';
import PageBase from '../PageBase/PageBase';
import './HonoredPage.css';

const HonoredPage = (props) => {
  // console.log('[Honored Person Page] started');
  const [honor, setHonored] = useState({});
  // const [isLoading, setIsLoading] = useState(false);

  const { getHonoredById, isLoading } = useContext(HonoredContext);
  const { id } = useParams();
  // console.log(honor);
  // console.log(id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getHonored = async () => {
    const honoredAux = await getHonoredById(id);
    // console.log(honoredAux);
    setHonored(honoredAux);
  };

  useEffect(() => {
    getHonored();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageBase>
      {/* <div className='honored-page'> */}
      {isLoading ? (
        <ColorRing
          visible={true}
          height='80'
          width='80'
          ariaLabel='blocks-loading'
          wrapperStyle={{}}
          wrapperClass='blocks-wrapper'
          colors={['#fef200', '#ed1b24', '#146834', '#241c19', '#fef200']}
        />
      ) : (
        <div className='honored-page-main'>
          <div className='honored-page-main-col'>
            <CardWithDate honored={honor} />
            <div className='honor-details'>
              <p className='text1'>Cidade</p>
              <p>{honor.city}</p>
              {honor.facebook || honor.instagram || honor.linkedin ? (
                <p className='text1 mt-20'>Redes Sociais</p>
              ) : (
                <p className='text1 mt-20'>Redes Sociais Não Informadas</p>
              )}
              <div className='icons-container'>
                {honor.facebook && (
                  <a
                    className='link'
                    href={honor.facebook}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FiFacebook className='icon mr-10' />{' '}
                  </a>
                )}
                {honor.instagram && (
                  <a
                    className='link'
                    href={honor.instagram}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FiInstagram className='icon  mr-10' />
                  </a>
                )}
                {honor.linkedin && (
                  <a
                    className='link'
                    href={honor.linkedin}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FiLinkedin className='icon mr-10' />
                  </a>
                )}
              </div>
              {honor.interviewUrl && (
                <>
                  <p className='text1 mt-20'>Entrevista</p>
                  <p>Link da entrevista</p>
                </>
              )}
              <div className='mt-20'>
                <a
                  className='link mt-20'
                  href={honor.decreeURL}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Decreto
                </a>
              </div>
            </div>
          </div>
          <div className='honored-page-main-col honored-bio text'>
            <p>{honor.biography}</p>
          </div>
        </div>
      )}
      {/* </div> */}
    </PageBase>
  );
};

export default HonoredPage;
