import { honoredList } from '../../constants/honoredlist';
import './HonoredList.css';

const HonoredList = () => {
  return (
    <div className='honoredlist'>
      <p className='title mb-10'>Lista de todas as pessoas homenageadas</p>
      {honoredList.sort((a, b) => b.year - a.year).map((honored) => {
        return <p className='white'>{honored.name}-{honored.year}</p>;
      })}
    </div>
  );
};

export default HonoredList;
