import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from 'firebase/storage';
import { getAuth } from "firebase/auth";


import getEnvVars from '../environment';

const { FirebaseConfig } = getEnvVars();
const app = initializeApp(FirebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { app, db, storage, ref, auth };
