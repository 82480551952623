// import Photo from '../Photo/Photo';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { formatDateToDDMMYYYY } from '../../util/validation';
import './ShowHonored.css';
import Card from '../Card/Card';

const ShowHonored = ({ honoredList, changeRecord, deleteRecord }) => {
  //   console.log('[ShowHonored comp.]', JSON.stringify(honoredList, null, 2));
  // console.log(honoredList);

  // honoredList.map((honored) => {
  //   console.log(formatDateToDDMMYYYY(honored.birthDate));
  // });

  return (
    <div className='show-honored'>
      <table>
        <thead>
          <tr>
            <th>Foto</th>
            <th>Nascimento</th>
            <th>Biografia</th>
            <th>Ano da Homenagem</th>
            <th>Cidade</th>
            <th>Alterar</th>
            <th>Exluir</th>
          </tr>
        </thead>
        <tbody>
          {honoredList.map((honored, index) => {
            if (!honored.deleted) {
              return (
                <tr key={index}>
                  <td>
                    {/* <div style={{ width: '200px', height: '200px' }}> */}
                    {/* <Photo photo={honored.photo}></Photo> */}
                    <Card honored={honored} />
                    {/* </div> */}
                  </td>
                  <td>{formatDateToDDMMYYYY(honored.birthDate)}</td>

                  {/* <td> 
                    {honored.deathDate !== '' &&
                      formatDateToDDMMYYYY(honored.deathDate)}
                  </td> */}
                  <td>{honored.biography}</td>
                  <td>{honored.honorYear}</td>
                  <td>{honored.city}</td>
                  <td>
                    <FaRegEdit
                      color='red'
                      onClick={() => {
                        changeRecord(honored.id);
                      }}
                    />
                  </td>
                  <td>
                    <FaRegTrashAlt
                      color='red'
                      onClick={() => {
                        deleteRecord(honored.id);
                      }}
                    />
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ShowHonored;

// import { AiOutlineEdit } from 'react-icons/fa';
// class Question extends React.Component {
//   render() {
//     return <h3> Lets go for a <FaBeer />? </h3>
//   }
// }

// AiOutlineEdit
