const lawUrl =
  'https://sagl-portal.campinas.sp.leg.br/generico/pdfJS/viewer_html?file=https://sagl-portal.campinas.sp.leg.br/sapl_documentos/norma_juridica/16485_texto_integral.pdf?1698937035.43';
const ifspCampinasUrl = 'https://portal.cmp.ifsp.edu.br/';
const minhaCampinasUrl = 'https://minhacampinas.org.br/'

const aboutProjectText = [
  {
    paragraph:
      'Nos últimos anos estamos vendo um aumento ou uma publicidade maior do preconceito racial que permeia a sociedade brasileira. Durante o período de pandemia 2020-2022, casos como o de americano George Floyd asfixiado por um policial e o brasileiro João Alberto Silveira Freitas espancado e assassinado pelos seguranças do Supermercado Carrefour em Porto Alegre evidenciam esse fato. No Rio de Janeiro, no supermercado Extra, o negro Pedro Henrique de Oliveira Gonzaga foi morto enforcado; em Salvador, no supermercado Ricoy, um jovem negro de 17 anos foi despido, amarrado, amordaçado e chicoteado com fios elétricos. Observa-se que estes casos  tem se repetido em nosso país, provocando o genocídio da população negra. Além dos casos citados acima, o portal Geledés mostra o relato de 20 casos de preconceito racial, que demonstra como a sociedade brasileira está longe de ser igualitária no tratamento racial. Essas pessoas foram sequestradas, escravizadas e assassinadas por mais 500 anos e a miscegenação, como forma de exterminio de negros e indígenas, endossado pelo Movimento Eugenista.',
  },
  {
    paragraph:
      ' Diante disso, o país possui uma dívida histórica com a população negra, que foi vitima de leis excludentes que visavam privar estes grupos de direitos básicos para integração destes na sociedade, por isso a abolição da escravidão é considerada “inacabada”. Como forma de resgatar a cultura negra no Brasil foi instituída a lei 10.639/2003, que diz respeito ao ensino a cultura afro-brasileira na educação básica como uma forma de ressignificar a história do negro no Brasil e a apresentar novas narrativas a partir dos protagonistas que partilham da vivência de uma pessoa negra, de modo, a inibir, que casos como esses citados, continuem acontecendo. Uma forma de coibir é mostrando a importância social do povo negro para a nossa sociedade. Por esse motivo se torna fundamental ações que deem visibilidade a personalidades negras, de forma a mostrar para à nossa sociedade, a importante contribuição dos negros e negras deram ao nosso país e continuam dando.',
  },
  {
    paragraph:
      'A cidade Campinas, na época da escravização, era considerada o município que submetia às pessoas escravizadas aos piores castigos e torturas, onde essas pessoas tinham pânico de serem vendidas como mercadorias para os senhores de engenho. Além disso, foi a última a libertar as pessoas negras. Os grupos marginalizados pela elite campineira foram criando formas de se organizar nos novos “quilombos urbanos” nas periferias, buscando resgatar a sua cultura por meio dos ritmos musicais, escolas de samba e pelas vivências das religiões de matrizes africanas e pela musicalidade das danças afros. Um exemplo é o Jongo que permite a reprodução de canções originárias do período escravocrata e a recuperação de algumas palavras de origem da língua materna africana, bem como a dança. Apesar desse duro passado escravocrata Campinas instituiu a Lei 801/1995 de 21 de novembro de 1995 que institui no município de Campinas o Diploma de Mérito "Zumbi dos Palmares" que é outorgado pelo Legislativo Campineiro para dar visibilidade a pessoas negras mais se destacaram na defesa, na integração social dos membros do comunidade negra de Campinas, bem como na difusão do cultura afro-brasileira. As primeiras homenagens são datadas a partir de 1998 pelo decreto 1.149/1998. ',
  },
  {
    paragraph:
      ' Deste período até os dias atuais as pessoas agraciadas com o diploma contabilizam cerca de 500 pessoas. Por conseguinte, institui o Conselho de Desenvolvimento e Participação da comunidade Negra Comunidade Negra pelo decreto 10.813/2001, sancionada pelo Prefeito Antonio da Costa Santos o “Toninho” político assassinado em setembro/2001, e por fim a lei  11.128/2002, que institui na cidade de Campinas o feriado em comemoração do dia da consciência negra, do vereador Sebastião Arcanjo, sancionada pela prefeita Izalene Tiene. Com a criação do Conselho em 2001, as indicações são provenientes dos conselheiros e também de vereadores da Câmara, os quais são indicados por políticos negros em sua maioria e/ou de pessoas não negras que são envolvidos na luta antirracista na cidade. No entanto, percebe-se que as homenagens não são publicizadas de modo permanente, nem no site da Câmara e nem em outro sítio público na internet. Figura-se apenas como notícias marcadas pelas sessões solenes. Esse projeto teve como um dos objetivos a criação desse site  com as pessoas que foram homenageadas com o diploma Zumbi dos Palmares.',
  },
];

const methodology = [
  {
    paragraph:
      'Nessa primeira fase do projeto foram mapeadas as pessoas homenageadas nos anos de 2020, 2021 e 2022, além de algumas pessoas ícones na cidade de Campinas. No entanto, com o lançamento e a publicidade do site espera-se que outras pessoas, que foram homenageadas, possam preencher o formulário e serem incluídas no site.',
  },
]

const team = [
  {
    name: 'Tatiane Helena Borges de Salles',
    email: 'tatiane.salles@ifsp.edu.br',
    photo: require('../assets/images/tatiane.jpeg'),
    position: 'Pesquisadora Bibliotecária',
  },
  {
    name: 'André Luís Bordignon',
    email: 'albordignon@gmail.com',
    position: 'Professor pesquisador',
    photo: require('../assets/images/andre.jpeg'),
  },
  {
    name: 'Rosangela da Silva Gomes',
    email: 'rosangela.gomes@ifsp.edu.br',
    position: 'Pesquisadora Bibliotecária',
    photo: require('../assets/images/rosangela.jpeg'),
  },
  {
    name: 'Lais Eriane da Silva',
    email: 'lais.e@aluno.ifsp.edu.br',
    position: 'Aluna bolsita',
    photo: require('../assets/images/lais.jpeg'),
  },
  {
    name: 'Walquíria Gonçalves Barbosa',
    email: 'walquiria.goncalves@aluno.ifsp.edu.br',
    position: 'Aluna bolsista',
    photo: require('../assets/images/walquiria.jpeg'),
  },
  {
    name: 'Denis Forigo',
    email: '"Denis Forigo Amigo Vinicius Costa." <denisforigo@gmail.com>',
    position: 'Designer',
    photo: require('../assets/images/denisforigo.webp'),
  },
];

export { lawUrl, ifspCampinasUrl, minhaCampinasUrl, aboutProjectText, team, methodology };
