import { useContext, useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import SearchBar from '../SearchBar/SearchBar';
import Card from '../Card/Card';
import { HonoredContext } from '../../context/HonoredProvider';
import HonoredList from '../HonoredList/HonoredList';

import './Honored.css';
import PageBase from '../PageBase/PageBase';

const Honored = () => {
  const [years, setYears] = useState(['2022']);
  const [honoredToShow, setHonoredToShow] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  // const [honored, setHonored] = useState([]);
  const { honored } = useContext(HonoredContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const getData = async () => {
  //   await getHonored();
  // }

  // console.log(honored);
  useEffect(() => {
    // getData();
    const yearsAux = [];
    setOnLoading(true);
    honored.map((honor) => {
      const result = yearsAux.find((item) => item.year === honor.honorYear);
      if (!result) {
        const year = { year: honor.honorYear, clicked: false };
        yearsAux.push(year);
      }
      setOnLoading(false);
      return true;
      // console.log(yearsAux);
    });
    // console.log(yearsAux);
    yearsAux.sort((a, b) => a.year - b.year);
    setYears(yearsAux);
  }, []);

  // console.log(years);

  const handleYearClick = (year) => {
    // console.log('Handle year click', year);
    const yearsAux = years;
    const result = yearsAux.find((item) => item.year === year);
    console.log(result);
    result.clicked = !result.clicked;
    setYears(yearsAux);
    //Setting honor to show
    const honoredToShowAux = [];
    yearsAux.map((year) => {
      if (year.clicked) {
        honored.map((honored) => {
          if (honored.honorYear === year.year) {
            console.log('setei');
            honoredToShowAux.push(honored);
          }
          return true;
        });
      }
      return true;
    });
    // const filteredItems = honored.filter(honored => yearsAux.includes(honored.honorYear));
    // console.log(filteredItems);
    console.log(honoredToShowAux);
    setHonoredToShow(honoredToShowAux);
  };

  const filterData = (searchText) => {
    const filteredData = honored.filter((honor) => {
      return (
        honor.honorYear === searchText ||
        honor.name.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    return filteredData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('[Hnored] handle submit', e.target.searchText.value);
    const filteredData = filterData(e.target.searchText.value);
    setHonoredToShow(filteredData);
  };

  return (
    <PageBase>
      <div className='honored'>
        <h1 className='title m-30'>Pessoas Homenageadas</h1>
        {onLoading ? (
          <div>
            <h3 className='text-center white'>Carregando</h3>
            <div className='loading'>
              <ColorRing
                visible={true}
                height='80'
                width='80'
                ariaLabel='blocks-loading'
                wrapperStyle={{}}
                wrapperClass='blocks-wrapper'
                colors={['#fef200', '#ed1b24', '#146834', '#241c19', '#fef200']}
              />
            </div>
          </div>
        ) : (
          <div className='filter-container'>
            <p className='text ml-30 mr-30'>Filtrar por ano:</p>
            {years.map((year, index) => {
              // console.log(years);
              // console.log('Ano :', year);
              let style = 'year-button mr-10 mb-10';
              if (year.clicked) {
                // console.log('setting button');
                style = 'year-button-clicked mr-10';
              }
              return (
                <div
                  key={index}
                  className={style}
                  onClick={() => handleYearClick(year.year)}
                >
                  <p>{year.year}</p>
                </div>
              );
            })}
          </div>
        )}

        {/* <div className='divider mt-30'></div> */}
        <div className='honored-container'>
          {honoredToShow.map((honor, index) => {
            return <Card key={index} honored={honor} />;
          })}
        </div>
        <div className='search-bar-container mt-30'>
          <SearchBar handleSubmit={handleSubmit} />
        </div>
      </div>
      <div>
        <HonoredList />
      </div>
    </PageBase>
  );
};

export default Honored;
