import './SideBar.css';
import HeaderButton from '../Header/HeaderButton';
import MainImage from '../MainImage/MainImage';

const SideBar = () => {
  return (
    <nav className='side-bar'>
      <div className='side-bar-image-container'>
        <MainImage />
      </div>
      <div className='side-bar-item-container'>
        <HeaderButton label='Sobre o Diploma' link='/sobre' />
      </div>
      <div className='side-bar-item-container'>
        <HeaderButton label='Homenagens' link='/homenagens'  />
      </div>
      <div className='side-bar-item-container'>
        <HeaderButton label='Galeria de Imagens' link='/galeriadeimagens' />
      </div>
      <div className='side-bar-item-container'>
        <HeaderButton label='Links' link='/links' />
      </div>
    </nav>
  );
};

export default SideBar;
