const ENV = {
    dev: {
        FirebaseConfig: {
            apiKey: "AIzaSyCiij9S7YlEoKJdeaMhF--aNtPYcJLVttw",
            authDomain: "diplomazumbi-dev.firebaseapp.com",
            projectId: "diplomazumbi-dev",
            storageBucket: "diplomazumbi-dev.appspot.com",
            messagingSenderId: "715871239168",
            appId: "1:715871239168:web:04a3c4c681bde0858dace5"
        },
    },
    prod: {
        FirebaseConfig: {
            apiKey: "AIzaSyDd-7RqfcQ5NP3uVFVfpvRByVv8bM1nU5k",
            authDomain: "diplomazumbi.firebaseapp.com",
            projectId: "diplomazumbi",
            storageBucket: "diplomazumbi.appspot.com",
            messagingSenderId: "197225119145",
            appId: "1:197225119145:web:9be60455440445d63dd8b8"
        },
    },
};

const getEnvVars = () => {
    console.log('Modo:',process.env.NODE_ENV); 
    if (process.env.NODE_ENV === 'development') {
        return ENV.dev;
    }
    return ENV.prod;
};

export default getEnvVars;