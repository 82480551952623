import { formatDateToDDMMYYYY } from '../../util/validation';
import './CardWithDate.css';

const CardWithDate = ({ honored }) => {
  return (
    <div className='card-with-date'>
      <div className='card-with-date-photo-container'>
        <img src={honored.photo} alt={honored.alt} />
        {/* <img
          src={require('../../assets/photos/WhatsApp Image 2023-07-10 at 20.10.50.jpeg')}
          alt='teste'
        /> */}
      </div>
      <div className='card-with-date-text-container'>
        <div className='ml-10'>
          <p style={{ color: '#FFFFFF' }}>{honored.name}</p>
          <p className='bold' style={{ color: '#F6BA45', fontSize: '10px' }}>
            {honored.birthDate && formatDateToDDMMYYYY(honored.birthDate)}
          </p>
        </div>
        <div className='card-year mr-10'>
          <p>{honored.honorYear}</p>
        </div>
      </div>
    </div>
  );
};

export default CardWithDate;
