import './RecentHonored.css';
import Card from '../Card/Card';

const RecentHonored = ({ honoredToShow }) => {
  console.log('[Recent Honored Component] started');

  return (
    <div className='recent-honored'>
      <p className='title2'>Algumas das Pessoas Homenageadas</p>
      <div className='recent-honored-container'>
        {honoredToShow.map((honor, index) => {
          return <Card key={index} honored={honor} />;
        })}
      </div>
    </div>
  );
};

export default RecentHonored;
