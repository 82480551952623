import PageBase from '../PageBase/PageBase';
import './Links.css';

const Links = () => {

  return (
    <PageBase>
      <div className='links'>
        <h1 className='title mb-30'>Links</h1>
        
      </div>
    </PageBase>
  );
};
export default Links;
