export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export const isValueNotBlank = (value) => {
  return value.trim() !== '';
}

// export const isValidDate = (dateString) => {
//   console.log('---', dateString);
//   const regex =
//     /^(Sun|Mon|Tue|Wed|Thu|Fri|Sat)\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{1,2}\s\d{4}\s\d{2}:\d{2}:\d{2}\s(GMT|UTC)(\+|\-)\d{4}$/;
//     console.log(regex.test(dateString));
//   return regex.test(dateString);
// };

export const isValidDateFormat = (dateString) => {
  const dateRegex =
    /^(Sun|Mon|Tue|Wed|Thu|Fri|Sat) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4} \(.*\)$/;
  console.log(dateRegex.test(dateString));

  return dateRegex.test(dateString);
}

export const isNumber = (value) => {
  return typeof value === 'number' && !isNaN(value);
}

export const formatDateToDDMMYYYY = (dateString) => {
  // const date = new Date(dateString);
  const dateObject = dateString.toDate();
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  };
  const formattedDate = dateObject.toLocaleDateString('pt-BR', options);
  return formattedDate


  // const day = String(date.getDate()).padStart(2, '0');
  // const month = String(date.getMonth() + 1).padStart(2, '0');
  // const year = date.getFullYear();
  // return `${day}/${month}/${year}`;
}

export const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export const isValidEmail = (emailString) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailString)) {
    return true;
  }
  return false;
}
