import './Header.css';
import HeaderButton from './HeaderButton';

const Header = () => {
  return (
    <nav className='header'>
      <HeaderButton label='Sobre o Diploma' link='/sobre' />
      <HeaderButton label='Homenagens' link='/homenagens'/>
      <HeaderButton label='Galeria de Imagens' link='/galeriadeimagens'/>
    </nav>
  );
};

export default Header;
