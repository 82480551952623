import React, { useEffect, useState, useContext } from 'react';
import { ColorRing } from 'react-loader-spinner';
import { FaRegTrashAlt } from 'react-icons/fa';
import { ImagesContext } from '../../context/ImagesProvider';
import {
  collection,
  addDoc,
} from 'firebase/firestore';
import { db } from '../../constants/FirebaseConfig';
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';

import './AddPhotos.css';

// import firebase from 'firebase/app';
// import 'firebase/storage';
// import 'firebase/firestore';

const AddPhotos = () => {
  const [photos, setPhotos] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [photosUploaded, setPhotosUploaded] = useState(0);
  const [processConcluded, setProcessConcluded] = useState(false);

  const { images, deleteImage, fetchImages } = useContext(ImagesContext);

  useEffect(() => {
    if (photosUploaded > 0 && photosUploaded === photos.length) {
      setPhotos([]);
      setProcessConcluded(true);
    }
  }, [photosUploaded]);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const updatedPhotos = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        const reader = new FileReader();
        const readAsDataURL = () =>
          new Promise((resolve) => {
            reader.onload = (e) => {
              resolve({ image: file.name, name: e.target.result, file: file });
            };
          });
        reader.readAsDataURL(file);
        const photo = await readAsDataURL();
        updatedPhotos.push(photo);
      }
    }
    setPhotos(updatedPhotos);
  };

  const addLegends = () => {
    const photosAux = [...photos];
    photosAux.map((photo, index) => {
      let legend = document.getElementById('id' + index).value;

      photosAux[index].legend = legend;
      return true;
    });
    setPhotos(photosAux);
  };

  const handleUpload = async (index) => {
    setOnLoading(true);
    await addLegends();
    // const photoName = Date().toString();
    const storage = getStorage();
    const photosAux = [...photos];
    // let numberOfPhotos = photos.length;
    let numberOfPhotosUploaded = 0;
    photosAux.map((photo) => {
      setOnLoading(true);

      const storageRef = ref(storage, `Images/${photo.image}`);
      const uploadTask = uploadBytesResumable(storageRef, photo.file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(progress);
          // setProgresspercent(progress);
        },
        (error) => {
          alert(error);
        },
        async () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            console.log(downloadURL);
            photo.url = downloadURL;
            try {
              const docRef = await addDoc(collection(db, 'images'), {
                name: photo.image,
                url: downloadURL,
                legend: photo.legend,
                createdAt: new Date(),
              });
              console.log('Document added with ID:', docRef.id);
              // alert(`Foto ${photo.legend} gravada com sucesso !!!`);
              numberOfPhotosUploaded++;
              setPhotosUploaded(numberOfPhotosUploaded);
              setOnLoading(false);
              fetchImages();
            } catch (error) {
              console.error('Error adding document:', error);
            }
            // setImgUrl(downloadURL);
          });
        }
      );
    });
    // setOnLoading(false);
    setPhotos(photosAux);
    //Add to the firestore
  };

  const deleteImg = (image) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      'Confirma a deleção da imagem ' + image.legend
    );
    if (confirmation) {
      deleteImage(image);
    }
  };

  return (
    <div className='add-photos'>
      <h2 className='title mt-30'>Incluir Fotos</h2>
      <div>
        <label htmlFor='files' className='input-button'>
          Selecione quantas imagens vocês quiser
        </label>
        <input
          type='file'
          id='files'
          style={{ visibility: 'hidden' }}
          multiple
          onChange={(e) => handleFileChange(e)}
        />
      </div>
      <div className='show-photos'>
        <div className='photos-container'>
          {photos.map((photo, index) => {
            return (
              <div className='photo-container' key={index}>
                <img src={photo.name} alt='mmmm' />
                <input type='text' className='legend' id={'id' + index} />
              </div>
            );
          })}
        </div>
        {photos.length > 0 && (
          <button className='button' onClick={() => handleUpload()}>
            Gravar
          </button>
        )}
        {onLoading && (
          <div className='loading'>
            <h3>Carregando</h3>
            <h3>Fotos para carregar: {photos.length}</h3>
            <h3>Fotos Carregadas: {photosUploaded}</h3>
            <ColorRing
              visible={true}
              height='80'
              width='80'
              ariaLabel='blocks-loading'
              wrapperStyle={{}}
              wrapperClass='blocks-wrapper'
              colors={['#fef200', '#ed1b24', '#146834', '#241c19', '#fef200']}
            />
          </div>
        )}
      </div>
      {images.length > 0 && (
        <div className='images-gallery'>
          <h2 className='title1 text-center'>
            Fotos armazenadas no banco de imagens
          </h2>
          <div className='images-gallery-images'>
            {images.map((image, index) => {
              return (
                <div key={index}>
                  <div className='photo-container'>
                    <img src={image.url} alt={image.legend} />
                    <p>{image.legend}</p>
                    <FaRegTrashAlt
                      color='red'
                      onClick={() => {
                        deleteImg(image);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPhotos;
